import { Heading } from '@troon/ui';
import TroonGolfLandingPages from './_components/layout';
import type { RouteDefinition } from '@solidjs/router';

export default function TroonGolfArizonaLandingPage() {
	return (
		<TroonGolfLandingPages
			title="Troon Golf Southeast"
			image="troon-golf-southeast-white.png"
			slugs={slugs}
			location="Southeast"
		>
			<Heading as="h2">Experience the best golf in the Southeast with Troon.</Heading>
			<p>
				Experience Troon Golf in the sunny Southeast. Troon Golf's unique collection of private and daily fee facilities
				in the Southeast are among the best in the region.
			</p>
		</TroonGolfLandingPages>
	);
}

const slugs = {
	'arlington-ridge-golf-club': '352.728.4660',
	'bent-creek-golf-course': '904.779.2100',
	'brentwood-golf-course': '904.924.0401',
	'burnt-store-marina-country-club': '941.637.4151',
	'cape-royal-golf-club': '239.283.5522',
	'chateau-elan-golf-club': '470.499.0193',
	'eagle-landing-golf-club': '904.291.5600',
	'fairways-country-club': '407.282.1059',
	'gateway-park-golf-course': '334.213.9370',
	'golf-club-of-ocala': '352.401.6917',
	'highland-park-golf-course': '800.492.6159',
	'ibis-landing-golf-club': '239.790.4361',
	'king-and-bear-at-world-golf-village-resort': '800.515.1627',
	'knoxville-municipal-golf-course': '865.691.7143',
	'lagoon-park-golf-course': '334.240.4050',
	'maggie-valley-club-and-resort': '800.496.3985',
	'mystic-creek-golf-club': '870.315.8965',
	'the-national-golf-and-country-club-at-ave-maria': '239.260.0053',
	'ocean-club-at-hutchinson-island': '800.775.5936',
	'pga-national-resort': '800.863.2819',
	'saddlebrook-golf-and-tennis-resort': '813.973.1111',
	'slammer-and-squire-at-world-golf-village-resort': '800.515.1627',
	'st-johns-golf-country-club': '904.940.3200',
	'stonebridge-golf-club': '706.236.5046',
	'sun-n-lakes-golf-club': '863.385.4830',
	'the-refuge-golf-course': '601.941.6116',
	'the-westin-savannah-harbor-golf-resort-and-spa': '800.513.0792',
	'tiburon-golf-club': '800.515.7881',
	'tobacco-road-golf-course': '919.297.2257',
	// TODO: Webb's has a bad slug (the last one). This makes forward compatible after we fix that…
	'webbs-reserve-golf-club': '941.220.4152',
	"webb's-reserve-golf-club": '941.220.4152',
	"Webb's-Reserve-Golf-Club": '941.220.4152',
	'wellen-park-golf-and-country-club': '941.202.2539',
	'whittle-springs-golf-course': '865.825.1022',
};

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
